<template>
  <!--begin::Advance Table Widget 9-->

  <div class="card card-custom card-stretch gutter-b">
    
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createPageClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('PAGES.REGISTRATION.NEW_PAGE') }}</a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 table-responsive">

      <b-table
        id="pages-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        class="mh-100 table-striped"
        sticky-header
      >
        <template #cell(type)="row">
          <span class="d-block font-size-lg">{{ getTypeString(row.item.type) }}</span>
        </template>
        <template #cell(id)="row">
          <div class='justify-content-end d-flex'>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="moveUpClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-arrow-up color-primary"></i>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="moveDownClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-arrow-down color-primary"></i>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="selectPageClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="deletePageClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

          </div>
        </template>
      </b-table>
    </div>


  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';
import PagesTableRow from '@/view/pages/ml/registrationsetup/PagesTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'pages-table',
  mixins: [ toasts ],
  props: ['items', 'currentCompanyId', 'types'],
  emits: ['createPageClicked', 'selectPageClicked', 'deletePageClicked', 'moveUpClicked', 'moveDownClicked', 'show_history_toggled'],
  components: {
    PagesTableRow
  },
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('show_history_toggled', newcheck);
    },
    items(newValue, oldValue) {
      const iFamily = newValue.findIndex(item => item.type === 'reg_family');
      const iDetails = newValue.findIndex(item => item.type === 'details');
      const iPersonnr = newValue.findIndex(item => item.type === 'personnr');
      const iStudent = newValue.findIndex(item => item.type === 'student');
      const iConfirm = newValue.findIndex(item => item.type === 'confirm');
      const iShop = newValue.findIndex(item => item.type === 'shop');
      const iAreaAlloc = newValue.findIndex(item => item.type === 'area_alloc');
      const iProperties = newValue.findIndex(item => item.type === 'properties');
      const iParents = newValue.findIndex(item => item.type === 'parents');


      this.familyInvalid = iFamily !== -1 ? ( iShop === -1 || iFamily < iShop ) : false;
      this.personnrInvalid = iPersonnr !== -1 ? ( iDetails === -1 || iPersonnr > iDetails) : false;
      this.parentsInvalid = iParents !== -1 ? ( iDetails === -1 || iParents < iDetails) : false;
      this.shopInvalid = iShop !== -1 ? ( iAreaAlloc === -1 && iShop < iAreaAlloc ) : false;
      this.confirmInvalid = iConfirm !== -1 ? ( newValue.length !== iConfirm+1 ) : false;
    }
  },
  methods: {
    createPageClicked() {
      this.$emit('createPageClicked');
    },
    selectPageClicked(id) {
      this.$emit('selectPageClicked', id);
    },
    deletePageClicked(id) {
      this.$emit('deletePageClicked', id);
    },
    moveUpClicked(id) {
      this.$emit('moveUpClicked', id);
    },
    moveDownClicked(id) {
      this.$emit('moveDownClicked', id);
    },
    getTypeString(type) {
      return this.types[type];
    },

  },
  data() {
    return {
      familyInvalid: false,
      personnrInvalid: false,
      parentsInvalid: false,
      shopInvalid: false,
      confirmInvalid: false,
      show_history: false,
      headers: [
        {
          key: 'name',
          label: 'Namn',
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'type',
          label: 'Typ',
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: true
        },
        {
          key: 'id',
          label: '',
          thClass: 'w-110px text-right pr-6 ',
        },
      ],
      list: []
    };
  }
};
</script>
